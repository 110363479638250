<template>
  <div>
    <div class="contain1"></div>
    <div class="contain2">
      <div class="contain2_left">
        <div class="contain2_left_title">积分任务</div>
        <div class="contain2_left_line" style="margin-bottom: 20px"></div>
        <div class="list1_item" v-for="(item, index) in list1" :key="index">
          <div>邀请注册奖励积分 +100积分</div>
          <div @click="jump()">去邀请</div>
        </div>
        <div
          class="contain2_left_line"
          style="margin-bottom: 50px; margin-top: 30px"
        ></div>
        <div class="contain2_left_title">任务规则</div>
        <div class="contain2_left_line" style="margin-bottom: 20px"></div>
        <div v-html="processedRichTextContent" class="text"></div>
      </div>
      <div class="contain2_left">
        <div class="contain2_left_title">积分记录</div>
        <div class="contain2_left_line" style="margin-bottom: 20px"></div>
        <div v-for="(item, index) in list" :key="index">
          <div class="list2_item">
            <div class="list2_item_left">
              <div>邀请注册奖励积分</div>
              <div>{{ item.create_time }}</div>
            </div>
            <div class="list2_item_right">+100 积分</div>
          </div>
          <div class="list2_item_line" v-if="list.length - 1 != index"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { get } from "/utils/request";
export default {
  data() {
    return {
      content: "",
      list1: [1], //积分任务
      list: [], //积分记录
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    processedRichTextContent() {
      return this.processImages(this.content);
    }
  },
  methods: {
    changeScreen(item) {
      this.current = item.name;
    },
    jump() {
      this.$router.push({
        path: "/invite",
      });
    },
    getList() {
      get("/invite/list").then((res) => {
        console.log(res);
        this.list = res.data ?? [];
      });
      get("/article", { identifier: "jfrwgz" }).then((res) => {
        console.log("返回的富文本---", res);
        this.content = res.content;
      });
    },
    processImages(content) {
      // 正则匹配图片路径
      const regex = /<img.*?src=["'](.*?)["']/g;
      let result;
      let newContent = content;
      // 使用正则表达式找到所有图片路径
      while ((result = regex.exec(content)) !== null) {
        const imagePath = result[1];
        // 拼接完整图片路径
        const fullImagePath = this.$imgURL + imagePath;
        // 替换原始图片路径并添加样式
        newContent = newContent.replace(
          `<img src="${imagePath}"`, 
          `<img src="${fullImagePath}" style="max-width:100%;height:auto;"`
        );
      }
      return newContent;
    }
  },
};
</script>
  
  <style lang="scss" scoped>
.contain1 {
  width: 1400px;
  height: 611px;
  background: url("../../assets/image/icon40.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 50px 0 0;
  box-sizing: border-box;
  margin: 20px auto 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 300px 0 0;
  box-sizing: border-box;
}
.contain2 {
  display: flex;
  justify-content: space-between;
  width: 1400px;
  margin: 20px auto 50px;
  .contain2_left {
    width: 690px;
    background: #ffffff;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    .contain2_left_title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #333333;
      letter-spacing: 0.9px;
      margin-bottom: 19px;
    }
    .contain2_left_line {
      height: 1px;
      background: #e0e0e0;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      letter-spacing: 0.7px;
    }
    .list1_item {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      div:nth-child(1) {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
      }
      div:nth-child(2) {
        width: 104px;
        height: 44px;
        border: 1px solid #23dc5a;
        border-radius: 6px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        color: #23dc5a;
        letter-spacing: 0.7px;
        line-height: 44px;
        cursor: pointer;
      }
    }
    .list2_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .list2_item_left {
        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #333333;
          letter-spacing: 0.7px;
          margin-bottom: 6px;
        }
        div:nth-child(2) {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.6px;
        }
      }
      .list2_item_right {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        letter-spacing: 0.7px;
      }
    }
    .list2_item_line {
      height: 1px;
      background: #e0e0e0;
      margin: 20px 0;
    }
  }
}
</style>